import React from "react";

export const Logo = () => {
  return (
    <picture>
      <source srcSet={`/images/logo.png`} type="image/png" />
      <img className="h-12" src={`/images/logo.png`} alt="krypto swap" />
    </picture>
  );
};
