import React from "react";
import { Logo } from "./logo";

export default function Navbar() {
  return (
    <div className="flex justify-between relative z-50 lg:container lg:mx-auto text-theme p-4 gap-3">
      <div className=" flex items-center">
        <Logo />
      </div>
    </div>
  );
}
