import React from "react";
import Button from "./ui/button";

export default function LiveDemo() {
  return (
    <div className="container mx-auto  w-full max-w-screen-xl lg:mt-44 mt-20 px-4">
      <div className="border-2 border-gray-500 hover:border-primary  flex lg:flex-row flex-col md:p-12 p-6 rounded-2xl lg:items-end items-start justify-between gap-6">
        <div className="flex flex-col gap-4">
          <h2 className="sm:text-4xl text-2xl">
            <span className="text-primary font-display font-medium text-4xl block">
              See Our Product in Action
            </span>
          </h2>
          <h4 className="text-base md:w-3/4 w-full max-w-2xl ">
            Get a firsthand look at the power and ease of use of KryptoSwap with
            our interactive live demo. Explore the platform's features, navigate
            the user interface, and understand how KryptoSwap can revolutionize
            your private label needs.{" "}
          </h4>
        </div>

        <a
          href={"https://www.kryptoswap.exchange/"}
          target="_blank"
          rel="noreferrer"
        >
          <Button modifier="outline" className="text-lg">
            Live Demo
          </Button>
        </a>
      </div>
    </div>
  );
}
