import React from "react";
import Button from "./ui/button";
import { IconBrandTelegram, IconPhoneCall } from "@tabler/icons-react";

export default function ContactUs() {
  return (
    <section className="w-full max-w-screen-xl flex lg:flex-row flex-col gap-4 items-center justify-center mx-auto sm:mt-24 mt-8 px-4">
      <div className="flex flex-col p-2 text-center items-center  gap-4 text-center sm:text-xl text-lg z-10">
        <h1 className="text-xl rtl:text-2xl sm:text-2xl rtl:sm:text-3xl lg:text-3xl rtl:lg:text-3xl leading-10 font-display font-book rtl:font-bold">
          Need further assistance? We're happy to help in other ways.{" "}
        </h1>
        <h2 className="text-sm md:text-xl rtl:text-lg rtl:sm:text-xl rtl:mt-0 text-gray-400 whitespace-pre-wrap font-normal md:w-4/6 w-full ">
          We offer various ways to get in touch, including phone, Telegram, and
          live chat. Choose the option that best suits your needs.{" "}
        </h2>

        <div className="flex flex-wrap items-center justify-center gap-4 mt-4 w-full">
          <a
            href={"https://t.me/kryptoswappp"}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              modifier="outline"
              icon={
                <IconBrandTelegram direction={"right"} className="h-5 w-5" />
              }
            >
              Our Telegram
            </Button>
          </a>

          <a href={`tel:+48 795337444`} aria-label="Call us">
            <Button
              modifier="outline"
              icon={<IconPhoneCall direction={"right"} className="h-5 w-5" />}
            >
              Phone Number
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
}
