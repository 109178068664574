import React from "react";
import type { ButtonProps } from "./button";
import Button from "./button";
import cx from "classnames";

type Props<T> = {
  options: Array<{ label: React.ReactNode | string; value: T }>;
  value: T;
  onChange: (value: T) => void;
  btnProps?: Partial<Omit<ButtonProps, "onPress">>;
  className?: string;
  name?: string;
  disabled?: boolean;
};

export function ToggleButtons<T extends string | number = string>({
  options,
  value,
  onChange,
  btnProps,
  className,
  name,
  disabled,
}: Props<T>) {
  return (
    <div className={cx("btn-group flex-nowrap", className)}>
      <input type="hidden" name={name} value={value} />
      {options.map((option) => (
        <Button
          modifier="outline"
          key={String(option.value)}
          onPress={() => onChange(option.value)}
          {...btnProps}
          className={cx(
            "focus-visible:outline-none focus-visible:border-base-content !rounded-none first-of-type:!rounded-is last-of-type:!rounded-ie -mis-[1px] first-of-type:mis-0",
            option.value === value
              ? "bg-primary/10 hover:bg-primary/20 disabled:bg-primary/20 border-primary hover:border-primary-focus disabled:border-primary-focus z-10 text-primary hover:text-primary-focus disabled:text-primary-focus"
              : "hover:bg-transparent text-base-content/60 hover:text-base-content",
            btnProps?.className
          )}
          disabled={disabled}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}
