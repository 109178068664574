import React from "react";
import Navbar from "./components/header";
import { Card, Carousel } from "./components/cards-carousel";
import PricingPlans from "./components/pricing-plans";
import Footer from "./components/footer";
import Hero from "./components/hero";
import ContactUs from "./components/contact-us";
import LiveDemo from "./components/live-demo";

function App() {
  const cards = data.map((card) => <Card key={card.src} card={card} />);
  return (
    <main
      data-theme="dark"
      className="bg-base-300 overflow-x-clip header-shadow font-sans w-full mx-auto"
    >
      <Navbar />
      <Hero />

      <PricingPlans />

      <div className="w-full h-full py-20 mb-48">
        <h2 className="container pl-4 mx-auto text-xl md:text-5xl font-bold text-neutral-800 dark:text-neutral-200 font-sans">
          Easy to Use
        </h2>
        <Carousel items={cards} />
      </div>

      <ContactUs />
      <LiveDemo />

      <Footer />
    </main>
  );
}

export default App;

const data = [
  {
    category: "Administrative Tools",
    title: "Centralized overview of your operations",
    src: "/images/en/1.png",
  },
  {
    category: "Financial Management",
    title: "View available currencies",
    src: "/images/en/2.png",
  },
  {
    category: "Financial Management",
    title: "Explore payment method options",
    src: "/images/en/3.png",
  },
  {
    category: "Administrative Tools",
    title: "Manage user information and preferences",
    src: "/images/en/4.png",
  },
  {
    category: "Administrative Tools",
    title: "anage user information and preferences",
    src: "/images/en/5.png",
  },
  {
    category: "Financial Management",
    title: "Keep your payment information up-to-date.",
    src: "/images/en/6.png",
  },
  {
    category: "Financial Management",
    title: "Expand your currency options",
    src: "/images/en/7.png",
  },

  {
    category: "Financial Management",
    title: "Offer diverse payment choices",
    src: "/images/en/8.png",
  },
  {
    category: "Administrative Tools",
    title: "View orders",
    src: "/images/en/9.png",
  },
];
