export default function Hero() {
  return (
    <section className="pt-24 mb-48">
      <div className="px-12 mx-auto max-w-7xl">
        <div className="w-full mx-auto text-start md:w-11/12 xl:w-9/12 md:text-center">
          <h1 className="mb-8 text-3xl font-semibold leading-none rtl:leading-tight tracking-normal text-white md:text-6xl md:tracking-tight rtl:tracking-normal flex flex-col">
            <span>Start Your</span>
            <span className="block w-full text-primary bg-clip-text leading-12 lg:inline">
              Exchanging Platform
            </span>
            <span>Now</span>
          </h1>
          <p className="px-0 mb-8 text-base text-gray-400 md:text-xl lg:px-28">
            Start your own exchanging platform today with our user-friendly
            solution. Take control of your financial future and begin growing
            your business now!
          </p>
        </div>
        <div className="w-full mx-auto mt-20 text-center md:w-10/12">
          <div className="relative z-0 w-full mt-8">
            <div className="absolute left-1/2 -translate-x-1/2 top-44 z-0 private-label-preview-shadow w-2/3 h-10 md:h-32"></div>
            <div className="relative z-20">
              <img
                src={`/images/en/1.png`}
                alt="admin dashboard"
                className="z-20 relative rounded-2xl border border-gray-500 shadow-2xl"
              />
              <img
                src={`/images/en/2.png`}
                alt="admin orders"
                className="z-10 absolute bottom-0 -left-1/2 rounded-2xl border border-gray-500 shadow-2xl h-[90%]"
              />
            </div>
            <img
              src={`/images/en/3.png`}
              alt="admin currencies"
              className="z-10 absolute bottom-0 -right-1/2 rounded-2xl border border-gray-500 shadow-2xl h-[90%]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
