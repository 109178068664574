import React from "react";
import Divider from "../components/divider";
import { ToggleButtons } from "./ui/toggle-buttons";
import { IconCircleCheckFilled } from "@tabler/icons-react";

const PricingPlans = () => {
  const [paymentCycle, setPaymentCycle] = React.useState<
    "monthly" | "annually"
  >("annually");

  const plans = [
    {
      key: "starter",
      title: "Starter",
      description:
        "Perfect for individuals and small businesses looking to establish a strong online presence.",
      price: {
        monthly: 349.99,
        annually: 329.99,
      },
      features: [
        "Your logo and business name",
        "Up to 300 orders per month",
        "Up to 150 users",
        "1 support agent and 1 admin",
        "Accepts payments in 2 currencies",
        "5 Payment options / Allows 2 Automations",
      ],
    },
    {
      key: "standard",
      title: "Standard",
      description:
        "Designed to scale with your growing business and enhance operational efficiency.",
      price: {
        monthly: 749.99,
        annually: 699.99,
      },
      features: [
        "Your logo and business name",
        "Up to 2000 orders per month",
        "Up to 1000 users",
        "2 Support Agents and 1 Admin",
        "Accepts payments in 5 currencies",
        "10 Payment options / Allows 2 Automations",
        "Includes initial technical set-up",
      ],
    },
    {
      key: "premium",
      title: "Premium",
      description:
        "Tailored for high-volume businesses demanding advanced features and maximum scalability.",
      price: {
        monthly: 1299.99,
        annually: 1199.99,
      },
      features: [
        "Your logo and business name",
        "Up to 5000 orders per month",
        "Up to 2500 users",
        "5 support agents and 2 admins",
        "Accepts payments in 15 currencies",
        "30 Payment options / Allows 2 Automations",
        "Includes initial technical set-up",
      ],
    },
  ];

  function getPercentage(plan: (typeof plans)[0]) {
    if (plan.price) {
      if (plan?.price?.monthly === 0) {
        return 0;
      }

      return Math.round(
        ((plan?.price?.monthly - plan?.price?.annually) /
          plan?.price?.annually) *
          100
      );
    }
    return 0;
  }

  return (
    <section>
      <div className="container mx-auto mb-48">
        <div className="w-full flex flex-col items-center">
          <span className="flex flex-col gap-2 items-center">
            <h2 className="container mx-auto text-xl md:text-5xl font-bold text-neutral-800 dark:text-neutral-200 font-sans">
              Our Pricing Plan
            </h2>
            <p className="text-base text-center rtl:text-sm text-body-color">
              Find the Perfect Fit for Your Business Needs
            </p>
          </span>
          <div className="h-0.5 section-line-gradient w-full max-w-[500px] m-4 after:section-line-gradient-after">
            <div className="h-0.5 section-line-gradient-inner w-full max-w-[500px] blur"></div>
          </div>
        </div>

        <div className="flex items-center justify-center my-12">
          <ToggleButtons
            className="!rounded-none"
            options={[
              { value: "monthly", label: "Monthly" },
              { value: "annually", label: "Annually" },
            ]}
            value={paymentCycle}
            onChange={(v) => setPaymentCycle(v)}
          />
        </div>

        <div className="z-10 flex flex-col gap-8 px-6 lg:flex-row">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="border border-gray-500 flex-1 p-10 flex flex-col gap-8 transition-all duration-150 rounded-2xl"
            >
              <div className="w-full text-center flex flex-col items-center gap-2">
                <span className="text-primary font-display font-medium text-4xl block">
                  {plan.title}
                </span>
                <span className="text-sm text-gray-300">
                  {plan.description}
                </span>
              </div>
              {plan.price && (
                <div className="flex flex-col gap-2 items-center">
                  <h2 className="font-ibm-plex-arabic font-bold text-center text-4xl">
                    {plan.price[paymentCycle] ?? 0} ${" "}
                    <span className="text-sm relative font-normal text-gray-300">
                      / Monthly
                      {paymentCycle === "annually" && (
                        <div className="absolute inline-end-1/3 block-end-full badge badge-xs badge-primary">
                          <span dir="ltr">{getPercentage(plan)}%</span>
                        </div>
                      )}
                    </span>
                  </h2>
                  {paymentCycle === "annually" ? (
                    <span className="text-sm">Billed annually</span>
                  ) : (
                    <span className="text-sm">month-to-month</span>
                  )}
                </div>
              )}
              <Divider />

              <ul className="flex flex-col gap-6">
                {plan.features?.map?.((feature, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <IconCircleCheckFilled className="shrink-0 w-4 h-4 text-primary" />
                    <span className="text-sm text-body-color">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;
