import { ReactNode } from "react";
import { Logo } from "./logo";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTelegram,
  IconBrandTiktok,
  IconBrandYoutube,
} from "@tabler/icons-react";

export default function Footer() {
  return (
    <footer className="p-4 sm:p-12 mt-48">
      <div className="mx-auto max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Logo />
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm sm:text-center text-gray-400">
            © {new Date().getFullYear()} krypto swap . All Rights Reserved.
          </span>
          <nav>
            <ul className="w-full flex flex-wrap md:flex-nowrap gap-1">
              <li>
                <SocialMediaItem
                  title="telegram"
                  link="https://t.me/kryptoswappp"
                >
                  <IconBrandTelegram className="w-5 h-5" />
                </SocialMediaItem>
              </li>
              <li>
                <SocialMediaItem
                  title="instagram"
                  link="https://www.instagram.com/krypto.swap?igshid=YmMyMTA2M2Y%3D"
                >
                  <IconBrandInstagram className="w-5 h-5" />
                </SocialMediaItem>
              </li>
              <li>
                <SocialMediaItem
                  title="facebook"
                  link="https://www.facebook.com/profile.php?id=61553221663819"
                >
                  <IconBrandFacebook className="w-5 h-5" />
                </SocialMediaItem>
              </li>
              <li>
                <SocialMediaItem
                  title="tiktok"
                  link="https://www.tiktok.com/@krypto_swap?_t=8hnZtlZjdce&_r=1"
                >
                  <IconBrandTiktok className="w-5 h-5" />
                </SocialMediaItem>
              </li>
              <li>
                <SocialMediaItem
                  title="youtube"
                  link="https://youtube.com/@KrSwap?si=fr_SOq-o70i-Y-GK"
                >
                  <IconBrandYoutube className="w-5 h-5" />
                </SocialMediaItem>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}

const SocialMediaItem = ({
  link,
  title,
  children,
}: {
  link: string;
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="tooltip" data-tip={title}>
      <a
        href={link}
        target="_blank"
        className="flex items-center justify-center bg-base-100 dark:bg-base:100 dark:text-base w-10 h-10 rounded-3xl shadow-md hover:bg-primary hover:text-white hover:-translate-y-1 transition-all duration-150"
        rel="noreferrer"
      >
        {children}
      </a>
    </div>
  );
};
