import React from 'react';
import cx from 'classnames';

export type ButtonVariant = 'primary' | 'secondary' | 'error' | 'success';
export type ButtonModifier = 'ghost' | 'outline' | 'filled';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  modifier?: ButtonModifier;
  variant?: ButtonVariant;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  unStyled?: boolean;
  width?: string;
  onPress?: (e: any) => void;
  iconBefore?: boolean;
};

const btnVariants = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  error: 'btn-error',
  success: 'btn-success',
};

const btnModifiers = {
  ghost: 'btn-ghost',
  outline: 'btn-outline',
  filled: 'btn-filled',
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      width,
      modifier,
      variant,
      children,
      className,
      icon,
      form,
      type = 'button',
      onPress,
      iconBefore,
      unStyled = false,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        form={form}
        className={
          unStyled
            ? className
            : cx(
                'btn gap-2',
                variant ? btnVariants[variant] : '',
                modifier ? btnModifiers[modifier] : '',
                width,
                className
              )
        }
        onClick={onPress}
        type={type}
        {...props}
      >
        {iconBefore && icon}
        {children}
        {!iconBefore && icon}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
